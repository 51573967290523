@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.fVINuDQ1sg3rFLynMxWm7Q\=\= {
    background: #F5F5F5;
    padding: 1em 0;
    width: calc(100vw + 1.3em);
}

.amlg2lQpZgsv0Xf8VT6Orw\=\= {
    font-size: xx-large;
    font-weight: bold;
}

.TXe9LD0Ckj4fFzWDrhOnCQ\=\= {
    font-size: xx-large;
    font-weight: bold;
    color: #a9a9a9;
}
.XNHl-5O2AaNii40dcSjM4w\=\=, .oseXspu83bsnclhOq-kTaQ\=\= {
    margin-left: 1em;
}

.oseXspu83bsnclhOq-kTaQ\=\=:enabled {
    background-color: white !important;
    color: black !important;
}

@media screen and (max-device-width: 840px) {
    .DqrQH5mSR3ivDyPUvnwJog\=\= {
        font-size: xx-large !important;
    }
    .XNHl-5O2AaNii40dcSjM4w\=\=, .oseXspu83bsnclhOq-kTaQ\=\= {
        padding-top: 0.25em !important;
        padding-bottom: 1.5em !important;
    }
}

.DqrQH5mSR3ivDyPUvnwJog\=\= {
    margin-top: 1em;
    margin-left: 1em;
    text-align: left;
}

html {
    box-sizing: border-box;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h5, h6, p, ul {
    margin: 0;
    padding: 0;
}

.JQbfY0lJ6PVruBzhxIA4TQ\=\= {
    padding: 10px;
}

.oMqrgyZDrY9HBldTxyrjmA\=\= {
    margin-top: 50px;
    padding: 10px;
    text-align: center;
}

.\+62\+FcvEdbmtse0yehgK5g\=\= {
    display: flex;
    overflow: hidden;
}

@media screen and (max-device-width: 840px) {
    .b-tUrGHWE03YICHV-a5Lhg\=\= {
        margin-left: -14.5em !important;
        float: none !important;
    }

    .n3AkpRa96t3PRQ-GSXOuvg\=\= {
        width: 100vw !important;
        margin: auto !important;
        height: auto !important;
        overflow-y: scroll !important;
        float: none !important;
    }

    .O9EDRJMmh7J\+bY261DOagA\=\= {
        width: 100% !important;
        margin-left: 0.5em !important;
    }

    h1 {
        font-size: xx-large !important;
        line-height: 120% !important;
        font-weight: bold !important;
    }

    section {
        font-size: xx-large !important;
        line-height: 120% !important;
    }

    h2, h3, h4, select, text, p {
        font-size: xx-large !important;
    }

    label {
        font-size: xx-large !important;
        line-height: 100% !important;
    }

    button {
        font-size: xx-large !important;
        margin: 0.2em !important;
    }

    .GRs5cxDp-Qim1\+Z-Zx6UEg\=\= {
        font-size: xx-large !important;
        margin: 0.2em 0.2em 0.2em 0em !important;
        width: 100% !important;
        padding-top: 0.25em !important;
        padding-bottom: 1.5em !important;
        background-color: white !important;
        color: black !important;
    }

    .jT06LsJwxpcIzi9JJyrljw\=\= {
        padding: 1em;
    }

    .za1kkZSiAHSK2npc10ftAQ\=\= {
        float: left;
        padding: 0.35em;
    }

    ._5SPrqcg5rvmNK\+S6bHozQw\=\= {
        display: inline-flex;
    }

    .nAn1VNptb\+38anThBR\+fjA\=\= {
        font-size: x-large;
        font-weight: bold;
        color: red;
    }
}

.b-tUrGHWE03YICHV-a5Lhg\=\= {
    margin: 0em 0em 0em 0em;
    float: right;
}

/*TODO: change this. */
.n3AkpRa96t3PRQ-GSXOuvg\=\= {
    width: 24em;
    margin: 0em 0em 3em 1em;
    float: left;
    height: calc(100vh - 9em);
    overflow-y: auto;
}

._9\+gJeaHmzS3dCWvdXkXouA\=\= {
    margin-left: auto;
    padding: 0.5em 0em 0.5em 1em;
    width: 24em;
    bottom: 0;
    background-color: #fff;
    text-align: center;
}

.O9EDRJMmh7J\+bY261DOagA\=\= {
    width: 18em;
}

.JEGmayU4o9fTv8G87I62lw\=\= {
    padding: 0 !important;
}

.SkvcKZEHF1f2Yf8i\+9TdYQ\=\= {
    color: #FF0000;
    padding: 1em;
    font-weight: bold;
}

._27PbZ1rcu0oJUFHLyrP7iQ\=\= {
    margin: 25vh auto;
    padding: 3.5em 0 0 1em;
    left: 42.55%;
    position: absolute;
    width: 10em;
    height: 10em;
    color: #29ABE2;
    border: 1em inset #29ABE2;
    border-radius: 50%;
}

.LLWUmbhH6T2tE8Rgqb0nBg\=\= {
    background-color: rgba(0, 0, 0, 0.6);
}

.D\+CL1JBdnTwcC9dzSUPLgQ\=\= {
    border-radius: 50%;
    width: 10em;
    height: 10em;
    border: 1em inset #29ABE2;
    -webkit-animation: M2t05swR0hAmwe0tOMy9cg\=\= 4s linear infinite;
    -moz-animation: M2t05swR0hAmwe0tOMy9cg\=\= 4s linear infinite;
    animation: M2t05swR0hAmwe0tOMy9cg\=\= 4s linear infinite;
    margin: 25vh auto;
    z-index: 1000;
    position: absolute;
    left: 42.55%;
}

.ZtTSyh\+NLrvsv1P2s94hKg\=\= {
    background-color: rgba(0, 0, 0, 0.6);
    height: calc(100vh - 8em);
    width: 100%;
    z-index: 999;
    position: fixed;
    left: 0;
    top: 0;
    margin: 8.2em 0 0 0;
}

@-webkit-keyframes M2t05swR0hAmwe0tOMy9cg\=\= {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes M2t05swR0hAmwe0tOMy9cg\=\= {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@keyframes M2t05swR0hAmwe0tOMy9cg\=\= {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.yYWy9zRVFchm2NBnnCJr9A\=\= {
    padding-bottom: 1em !important;
}

._8AeHNqyGGIl\+WK4hLLQX4Q\=\= {
    font-size: 200%;
}

.\+1mwMxFjNfeXFxoo\+wyx0g\=\= {
    font-weight: bold;
    font-size: larger;
}

.xeeZN9A1L473HhOigAIMEg\=\= {
    color: #000000;
    font-size: larger;
}

.H9GGpMt0hgnvO5DAdVikSA\=\= {
    font-weight: bold !important;
}

.fX0YnyZf9d16gA\+LCLj6mg\=\= {
    font-size: larger !important;
    font-weight: bold !important;
    text-transform: capitalize !important;
}

.q8PCX1SepVXOOyvJ9AKbCg\=\= {
    color: red;
    cursor: pointer;
}

._9S3I72KqiXZZpQPk2q5XYw\=\= {
    color: white;
    display: inline-block;
    padding-left: 2.5em;
    text-indent: -1em;
}

.TA\+SD-kgYoDlK9f4mar9YA\=\= {
    padding: 0.2em;
}

._18BtHcgIiFK4VRMpN3hlgg\=\= {
    font-weight: bold;
    color: white;
}

.y4W3Eb3tA4L\+8knvVBUIHQ\=\= {
    font-weight: bold;
    filter: invert(100%);
}

.h1pailV8aRo8Lw9dBzNKdg\=\= {
    position: fixed;
    z-index: 1;
    width: 15em;
    bottom: 0;
    left: 57%;
    margin: 0 auto 0.9em auto;
    background-color: rgba(0, 60, 136, 0.6) !important;
    border-radius: 0.2em;
    border: solid rgba(255, 255, 255, 0.7);
}

.m\+fCpShVuSYdtvzT1bNmuA\=\= {
    filter: invert(100%);
}

._5FM1iM9DXPmJFjLKWCVeog\=\= {
    width: 100%;
}

.tIgCXIxmGQ-uaN29NWOKQg\=\= {
    cursor: pointer;
    margin-left: -1em;
    position: relative;
}

.wCAcjWcvL2r1RvQ730KYPg\=\= {
    cursor: pointer;
    float: right;
    color: white;
    margin-right: 20px;
}

.wCAcjWcvL2r1RvQ730KYPg\=\= i {
    color: white;
}

html {
    box-sizing: border-box;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h5, h6, p, ul {
    margin: 0;
    padding: 0;
}

.OpSCwN-bJhI0vzdas5\+ocg\=\= {
    padding: 10px;
}

.WEqpUnNkvKD2uhZYcUWWhQ\=\= {
    margin-top: 50px;
    padding: 10px;
    text-align: center;
}

._0nlIT7KFEMMQb3vcqmiJAw\=\= {
    display: flex;
    overflow: hidden;
}

@media screen and (max-device-width: 840px) {
    .dn9LgPiMxK-45OAcTGFQLA\=\= {
        margin-left: -14.5em !important;
        float: none !important;
    }

    .-A0hvx40emWaE2Gm-keGCw\=\= {
        width: 100vw !important;
        margin: auto !important;
        height: auto !important;
        overflow-y: scroll !important;
        float: none !important;
    }

    .ns3GaTFyIeQ\+NPZpW-vmeA\=\= {
        width: 100% !important;
        margin-left: 0.5em !important;
    }

    h1 {
        font-size: xx-large !important;
        line-height: 120% !important;
        font-weight: bold !important;
    }

    section {
        font-size: xx-large !important;
        line-height: 120% !important;
    }

    h2, h3, h4, select, text, p {
        font-size: xx-large !important;
    }

    label {
        font-size: xx-large !important;
        line-height: 100% !important;
    }

    button {
        font-size: xx-large !important;
        margin: 0.2em !important;
    }

    .oQRH0eDJJ6nvZBI\+XVME8Q\=\= {
        font-size: xx-large !important;
        margin: 0.2em 0.2em 0.2em 0em !important;
        width: 100% !important;
        padding-top: 0.25em !important;
        padding-bottom: 1.5em !important;
        background-color: white !important;
        color: black !important;
    }

    ._0TLpo0AwxjYLs6gmq4xncg\=\= {
        padding: 1em;
    }

    .cQuZLG9Yvt\+LF\+lkHskRCQ\=\= {
        float: left;
        padding: 0.35em;
    }

    .bQNsyTL78Mcp8vubUm4-pw\=\= {
        display: inline-flex;
    }

    .LwVutLyGR0pp3FMwGZkHvw\=\= {
        font-size: x-large;
        font-weight: bold;
        color: red;
    }
}

.dn9LgPiMxK-45OAcTGFQLA\=\= {
    margin: 0em 0em 0em 0em;
    float: right;
}

/*TODO: change this. */
.-A0hvx40emWaE2Gm-keGCw\=\= {
    width: 24em;
    margin: 0em 0em 3em 1em;
    float: left;
    height: calc(100vh - 9em);
    overflow-y: auto;
}

.mHHK2MkanD3-J9wiB5cBMg\=\= {
    margin-left: auto;
    padding: 0.5em 0em 0.5em 1em;
    width: 24em;
    bottom: 0;
    background-color: #fff;
    text-align: center;
}

.ns3GaTFyIeQ\+NPZpW-vmeA\=\= {
    width: 18em;
}

.NT3Cl2DfIeK8TYGxsJF10g\=\= {
    padding: 0 !important;
}

.W\+UZ\+yZSFFlGATWIrJK-vQ\=\= {
    color: #FF0000;
    padding: 1em;
    font-weight: bold;
}

.SoWu7lH9UEZKqzF3VelK9g\=\= {
    margin: 25vh auto;
    padding: 3.5em 0 0 1em;
    left: 42.55%;
    position: absolute;
    width: 10em;
    height: 10em;
    color: #29ABE2;
    border: 1em inset #29ABE2;
    border-radius: 50%;
}

.uS57YPzrBwdhKlbA0xrR7Q\=\= {
    background-color: rgba(0, 0, 0, 0.6);
}

.C0rNrGoKFEHgAKHpVvycNw\=\= {
    border-radius: 50%;
    width: 10em;
    height: 10em;
    border: 1em inset #29ABE2;
    -webkit-animation: _5ecHzgtsbI32fN88SrCXsw\=\= 4s linear infinite;
    -moz-animation: _5ecHzgtsbI32fN88SrCXsw\=\= 4s linear infinite;
    animation: _5ecHzgtsbI32fN88SrCXsw\=\= 4s linear infinite;
    margin: 25vh auto;
    z-index: 1000;
    position: absolute;
    left: 42.55%;
}

.U9y54ub6XMon59NyyOXPlw\=\= {
    background-color: rgba(0, 0, 0, 0.6);
    height: calc(100vh - 3.8em);
    width: 100%;
    z-index: 999;
    position: fixed;
    left: 0;
    top: 0;
    margin: 3em 0 0 0;
}

@-webkit-keyframes _5ecHzgtsbI32fN88SrCXsw\=\= {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes _5ecHzgtsbI32fN88SrCXsw\=\= {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@keyframes _5ecHzgtsbI32fN88SrCXsw\=\= {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

._6T3QCfSnUFC2jvfb\+ECJZQ\=\= {
    padding-bottom: 1em !important;
}

.kAs7nmHVb-\+wd1a9u1UtAA\=\= {
    font-size: 200%;
}

.qgpzyU9oC0zZImhi0pL8xQ\=\= {
    font-weight: bold;
    font-size: larger;
}

.CjF3XmOBtSQ1Akw2zLWCmA\=\= {
    color: #000000;
    font-size: larger;
}

.HQMWifyGQJo2NXNs36Hq4A\=\= {
    font-weight: bold !important;
}

.Txc\+V4Z5-iZlMTjyG0flog\=\= {
    font-size: larger !important;
    font-weight: bold !important;
    text-transform: capitalize !important;
}

.c7mrM9wQGd0Ak6aISlHjIA\=\= {
    color: red;
    cursor: pointer;
}

.JvMgtLrGOJUgOXIGLHorPg\=\= {
    color: white;
    display: inline-block;
    padding-left: 2.5em;
    text-indent: -1em;
}

.SVEtkDIRdVYYO8YicjG94g\=\= {
    padding: 0.2em;
}

.RjYF\+ALJ01zI4TNrwdEFOg\=\= {
    font-weight: bold;
    color: white;
}

.\+\+jQIEkYks4TSPM28NxIAA\=\= {
    font-weight: bold;
    filter: invert(100%);
}

.UldUlo9eBt3YPAeW7Pb-Ig\=\= {
    position: fixed;
    z-index: 1;
    width: 15em;
    bottom: 0;
    left: 57%;
    margin: 0 auto 0.9em auto;
    background-color: rgba(0, 60, 136, 0.6) !important;
    border-radius: 0.2em;
    border: solid rgba(255, 255, 255, 0.7);
}

.i0ICP1NscvuAJc\+JAqkBlQ\=\= {
    filter: invert(100%);
}

.rOPqpsMYuWxi1ZI1nzuZ3Q\=\= {
    width: 100%;
}

.evuh-hzenVLf7yIEljUcZQ\=\= {
    cursor: pointer;
    margin-left: 1em;
    float: right;
}

.SAFfy45AMujymikbH4FyYA\=\= {
    cursor: pointer;
    float: right;
    color: white;
    margin-right: 20px;
}

.SAFfy45AMujymikbH4FyYA\=\= i {
    color: white;
}

@media screen and (max-device-width: 840px) {
    .bOrw7aysHOckCt3kOTk30w\=\= {
        width: 100vw !important;
        margin: auto !important;
        height: calc(100vh - 18em) !important;
        overflow-y: scroll !important;
        float: none !important;
    }

    .gLywxuJH6KhHj4hQewJg0g\=\= {
        margin-left: auto !important;
        padding: 0.5em 0em 0.5em 1em !important;
        width: 100vw !important;
        background-color: #fff !important;
    }
}

.bOrw7aysHOckCt3kOTk30w\=\= {
    width: 25em;
    margin: 4em 0 3em 1em;
    float: left;
    height: calc(100vh - 8.5em);
    overflow-y: auto;
}

.gLywxuJH6KhHj4hQewJg0g\=\= {
    margin-left: auto;
    padding: 0.5em 0em 0.5em 1em;
    width: 24em;
    bottom: 0;
    position: absolute;
    background-color: #fff;
}

.ol-box {
  box-sizing: border-box;
  border-radius: 2px;
  border: 2px solid blue;
}

.ol-mouse-position {
  top: 8px;
  right: 8px;
  position: absolute;
}

.ol-scale-line {
  background: rgba(0,60,136,0.3);
  border-radius: 4px;
  bottom: 8px;
  left: 8px;
  padding: 2px;
  position: absolute;
}
.ol-scale-line-inner {
  border: 1px solid #eee;
  border-top: none;
  color: #eee;
  font-size: 10px;
  text-align: center;
  margin: 1px;
  will-change: contents, width;
}
.ol-overlay-container {
  will-change: left,right,top,bottom;
}

.ol-unsupported {
  display: none;
}
.ol-viewport .ol-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.ol-control {
  position: absolute;
  background-color: rgba(255,255,255,0.4);
  border-radius: 4px;
  padding: 2px;
}
.ol-control:hover {
  background-color: rgba(255,255,255,0.6);
}
.ol-zoom {
  top: .5em;
  left: .5em;
}
.ol-rotate {
  top: .5em;
  right: .5em;
  transition: opacity .25s linear, visibility 0s linear;
}
.ol-rotate.ol-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s linear, visibility 0s linear .25s;
}
.ol-zoom-extent {
  top: 4.643em;
  left: .5em;
}
.ol-full-screen {
  right: .5em;
  top: .5em;
}
@media print {
  .ol-control {
    display: none;
  }
}

.ol-control button {
  display: block;
  margin: 1px;
  padding: 0;
  color: white;
  font-size: 1.14em;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  height: 1.375em;
  width: 1.375em;
  line-height: .4em;
  background-color: rgba(0,60,136,0.5);
  border: none;
  border-radius: 2px;
}
.ol-control button::-moz-focus-inner {
  border: none;
  padding: 0;
}
.ol-zoom-extent button {
  line-height: 1.4em;
}
.ol-compass {
  display: block;
  font-weight: normal;
  font-size: 1.2em;
  will-change: transform;
}
.ol-touch .ol-control button {
  font-size: 1.5em;
}
.ol-touch .ol-zoom-extent {
  top: 5.5em;
}
.ol-control button:hover,
.ol-control button:focus {
  text-decoration: none;
  background-color: rgba(0,60,136,0.7);
}
.ol-zoom .ol-zoom-in {
  border-radius: 2px 2px 0 0;
}
.ol-zoom .ol-zoom-out {
  border-radius: 0 0 2px 2px;
}


.ol-attribution {
  text-align: right;
  bottom: .5em;
  right: .5em;
  max-width: calc(100% - 1.3em);
}

.ol-attribution ul {
  margin: 0;
  padding: 0 .5em;
  font-size: .7rem;
  line-height: 1.375em;
  color: #000;
  text-shadow: 0 0 2px #fff;
}
.ol-attribution li {
  display: inline;
  list-style: none;
  line-height: inherit;
}
.ol-attribution li:not(:last-child):after {
  content: " ";
}
.ol-attribution img {
  max-height: 2em;
  max-width: inherit;
  vertical-align: middle;
}
.ol-attribution ul, .ol-attribution button {
  display: inline-block;
}
.ol-attribution.ol-collapsed ul {
  display: none;
}
.ol-attribution.ol-logo-only ul {
  display: block;
}
.ol-attribution:not(.ol-collapsed) {
  background: rgba(255,255,255,0.8);
}
.ol-attribution.ol-uncollapsible {
  bottom: 0;
  right: 0;
  border-radius: 4px 0 0;
  height: 1.1em;
  line-height: 1em;
}
.ol-attribution.ol-logo-only {
  background: transparent;
  bottom: .4em;
  height: 1.1em;
  line-height: 1em;
}
.ol-attribution.ol-uncollapsible img {
  margin-top: -.2em;
  max-height: 1.6em;
}
.ol-attribution.ol-logo-only button,
.ol-attribution.ol-uncollapsible button {
  display: none;
}

.ol-zoomslider {
  top: 4.5em;
  left: .5em;
  height: 200px;
}
.ol-zoomslider button {
  position: relative;
  height: 10px;
}

.ol-touch .ol-zoomslider {
  top: 5.5em;
}

.ol-overviewmap {
  left: 0.5em;
  bottom: 0.5em;
}
.ol-overviewmap.ol-uncollapsible {
  bottom: 0;
  left: 0;
  border-radius: 0 4px 0 0;
}
.ol-overviewmap .ol-overviewmap-map,
.ol-overviewmap button {
  display: inline-block;
}
.ol-overviewmap .ol-overviewmap-map {
  border: 1px solid #7b98bc;
  height: 150px;
  margin: 2px;
  width: 150px;
}
.ol-overviewmap:not(.ol-collapsed) button{
  bottom: 1px;
  left: 2px;
  position: absolute;
}
.ol-overviewmap.ol-collapsed .ol-overviewmap-map,
.ol-overviewmap.ol-uncollapsible button {
  display: none;
}
.ol-overviewmap:not(.ol-collapsed) {
  background: rgba(255,255,255,0.8);
}
.ol-overviewmap-box {
  border: 2px dotted rgba(0,60,136,0.7);
}

@media screen and (max-device-width: 840px) {
    .JPHOToPH3hcvvW9H6kq6Hg\=\= {
        height: calc(100vh - 20em) !important;
        width: calc(100vw - 1em) !important;
        cursor: pointer;
    }

    .nGUYKXdZRYpqD93TxhHq2A\=\= {
        font-size: x-large !important;
    }
}

.JPHOToPH3hcvvW9H6kq6Hg\=\= {
    height: calc(100vh - 3.8em);
    width: calc(100vw - 26.2em);
    cursor: pointer;
}

._5Xjn1NMdi8mvV7zPsi3f\+g\=\= {
    width: 20px;
    height: 20px;
    border: 1px solid #088;
    border-radius: 10px;
    background-color: #0FF;
    opacity: 0.5;
}

.nGUYKXdZRYpqD93TxhHq2A\=\= {
    position: absolute;
    background-color: white;
    -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    bottom: 28px;
    left: -48px;
    min-width: 28em;
    font-size: 80%;
    cursor: default;
    z-index: 1;
}

.nGUYKXdZRYpqD93TxhHq2A\=\=:after, .nGUYKXdZRYpqD93TxhHq2A\=\=:before {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.nGUYKXdZRYpqD93TxhHq2A\=\=:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}

.nGUYKXdZRYpqD93TxhHq2A\=\=:before {
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}

.UWoa-d3EVgiubquYvdHAPg\=\= {
    text-decoration: none;
    position: absolute;
    top: 9px;
    right: 8px;
    color: #ffffff;
}

.UWoa-d3EVgiubquYvdHAPg\=\=:after {
    color: #ffffff;
}

.sD9I6CVSeHiU0C1YDyo3Mw\=\= {
    bottom: .5em;
    left: .5em;
    border-radius: 0.15em;
}

._0r-YOU8j0P5nOIDn5gN6pg\=\= {
    top: 6.3em;
}

._0f2n6613PBXDMPYccocPcQ\=\= {
    top: 8.15em;
}

.MbyYjkzZEXGwV6Dq8VsBcA\=\= {
    top: 9.95em;
}

.WAuzaPVEaGDZsJpyETOyxg\=\= {
    top: 11.75em;
}

.l52T8ofncUn7MqJiCEObEg\=\= {
    left: .5em;
    border-radius: 0.15em;
    visibility: hidden;
}

._0xgvlKY1aRXKgcxp4gWbSg\=\= {
    margin: auto;
    border-collapse: separate;
    border-spacing: 0.4em;
    min-width: 28.1em;
    padding-left: 0.5em;
    background-color: #dcdcdc;
}

.Pb0hym48PMoVQ\+\+Fq4\+9TA\=\= {
    color: #ffffff;
    padding: 0.5em;
    font-weight: normal;
    font-size: x-large;
}

.F6jYGV4OZZccbfJu82HAvA\=\= {
    padding: 0.2em 0.2em 0.2em 1em;
}

.ay4zdKeypzsuWyJTPdZwDA\=\= {
    border-bottom: 0.2em solid #808080;
    min-height: 0;
}

.vNgY1H8S3q25EbfAL-YmHg\=\= {
    margin-bottom: 0.5em;
    overflow-y: auto;
    line-height: 1.5em;
}

.d4xanLdGhOrWk0y0Jl1o5Q\=\= {
    text-align: center;
    padding-top: 1em;
    font-size: larger;
    font-weight: bold;
    margin-top: 2.5em;
    position: relative;
    width: 4.9em;
    height: 3.5em;
    border-radius: 0% 0% 10% 10%;
}

.d4xanLdGhOrWk0y0Jl1o5Q\=\=:before {
    content: '';
    border-width: 0em 2.4em 2.2em 2.5em;
    border-color: transparent;
    border-bottom-color: inherit;
    border-style: solid;
    position: absolute;
    width: 0em;
    height: 0em;
    left: 0em;
    top: -2.2em;
}

.RypWIcl2ZvU7Rb0bvsrkqg\=\= {
    text-align: center;
    padding-top: 1.5em;
    font-size: larger;
    font-weight: bold;
    margin-bottom: 2.5em;
    position: relative;
    width: 4.9em;
    height: 3.5em;
    border-radius: 10% 10% 0% 0%;
}

.RypWIcl2ZvU7Rb0bvsrkqg\=\=:before {
    content: '';
    border-width: 2.2em 2.5em 0em 2.4em;
    border-color: transparent;
    border-top-color: inherit;
    border-style: solid;
    position: absolute;
    width: 0em;
    height: 0em;
    left: 0em;
    bottom: -2.15em;
}

.gAFciD-zl5LFUyD9moPaHg\=\= {
    text-align: center;
    padding-top: 1.8em;
    font-size: larger;
    font-weight: bold;
    width: 5em;
    height: 5em;
    border-radius: 50%;
}

.XydoDRGnL-Ex69RTpuIt3w\=\= {
    position: absolute;
    top: 5.8em;
    right: 0.5em;
}

.RDsRmHxr8t3IG0Qq0UrgiQ\=\= {
    width: 13em;
    padding: 0.5em;
    background: #FFFFFF;
    border: 0.2em solid #F5F5F5;
    border-radius: 5%;
}

@media screen and (max-device-width: 840px) {
    .RDsRmHxr8t3IG0Qq0UrgiQ\=\= {
        font-size: x-large;
    }
}

.CfFy5K4yPFcBkgm1RHhqGQ\=\= {
    border-bottom: 1em solid;
    border-bottom-color: inherit;
    border-right: 0.5em solid transparent !important;
    border-left: 0.5em solid transparent !important;
    display: inline-block;
}

.wVrV5fE4nBzY7qcfrYKoyA\=\= {
    border-top: 1em solid;
    border-top-color: inherit;
    border-right: 0.5em solid transparent !important;
    border-left: 0.5em solid transparent !important;
    display: inline-block;
}

.H5DWyuZW2Av7ui3OsFDvqg\=\= {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    display: inline-block;
}

.RLali4UdB\+\+z5l9ckdA7tQ\=\= {
    text-align: center;
    display: block;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-weight: bold;
    text-transform: uppercase;
}

.j8ORg3iP8giq4RnkUdVRWg\=\= {
    text-align: center;
    display: block;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-weight: bold;
}

@media screen and (max-device-width: 840px) {
    ._0xgvlKY1aRXKgcxp4gWbSg\=\=, .vNgY1H8S3q25EbfAL-YmHg\=\=, .RLali4UdB\+\+z5l9ckdA7tQ\=\=, .j8ORg3iP8giq4RnkUdVRWg\=\=, .f\+V1-2hhTki5UxqQx8YEmA\=\= {
        font-size: xx-large !important;
    }

    .Pb0hym48PMoVQ\+\+Fq4\+9TA\=\= {
        font-size: 200% !important;
    }

    .nGUYKXdZRYpqD93TxhHq2A\=\= {
        min-width: 30em !important;
    }
}

.f\+V1-2hhTki5UxqQx8YEmA\=\= {
    margin: auto;
    border-collapse: separate;
    border-spacing: 0.4em;
    min-width: 28em;
    padding-left: 0.5em;
    line-height: 1.2em;
}

.AmL7zI0hL9Z0sUoxfwjpTg\=\= {
    color: #808080;
}

.-ye3\+iDgo7F2lccifoRGOg\=\= {
    vertical-align: top !important;
}

.AfKdy1ZwksghDfMcgl1rfQ\=\= {
    color: #FF0000;
}

.JS3UQcFtZ8J\+JBLUn6fiNg\=\= {
    padding-left: 4em;
}

._0OxINLN\+O4b-jxpv8dwTNQ\=\= {
    display: inline-block;
    width: 300px;
    height: 500px;
    border: 1px black solid;
    margin: 50px;
    padding: 10px;
    background: white;
    overflow: auto;
}

.vJiapFa9mTQ\+8t8QpFjk9w\=\= {
    margin-bottom: 10px;
    border: 1px solid #ccc;
    font-size: 16px;
    height: 34px;
    width: 280px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.dW044w37d8\+TBW-u4TeT4Q\=\= {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.UokE9-6fXmv5FDAKqwe46g\=\=.d2nQ9sm64LeqFTLVWMZVEQ\=\= select {
    border: 1px solid #ccc;
    font-size: 16px;
    height: 34px;
    width: 268px;
}

.\+LrgIUFRcxDO7\+pq2o\+0\+g\=\= {
    float: left;
    font-size: smaller;
    width: 100%;
    overflow-y: auto;
}

.pUYu4g7VmFXomz\+XJefDZg\=\= {
    float: right;
    width: 1em;
    cursor: pointer;
}

.VpKDOkxdY6Wa2SztgcyN0Q\=\= {
    float: right;
    width: 1.5em;
    cursor: pointer;
    padding-left: 0.33em;
}

.dE7vhYZbQQDFdW2L7kOHHw\=\= {
    float: right;
    width: 1em;
    cursor: pointer;
}

.wONXOKKpDPJ\+WNlZSokerw\=\= {
    float: left;
    width: 15em;
}

.AZfZtNENBg1QIlvL4oOZ8Q\=\= {
    color: red;
}

._3-DfwT2a2wGMoPFIMFH1EA\=\= {
    float: left;
    margin-right: 0.2em;
    margin-top: 0.2em;
}

.AGh9DYCoMmSJ\+wugtUxsxg\=\= {
    float: left;
    margin-right: 0.2em;
}

.ZyUCTMn4\+OZ5tIe8izB8WQ\=\= {
    float: left;
    margin-right: 0.2em;
    color: #FFFFFF;
    background: #000000;
    border-radius: 100%;
}

._8XcxkIV95wb0ot5gS1v5pQ\=\= {
    padding: 0.2em 0.6em 0;
}

.Em-oYqy8e57Is80h6h0BEQ\=\= {
    width: 22em;
    margin: 1em 0 0 0;
    padding: 0.5em 1em 1em 1em;
    text-align: left;
    display: inline-block;
    overflow-y: auto;
    overflow-x: auto;
}

.\+pInU4c5LJbf7qYf666GTA\=\= {
    margin-top: 0.6em;
    margin-bottom: -0.5em;
    width: 80%;
}

.f7CHhpG4ea8Ru1yozEG29w\=\= {
    border-bottom-style: solid;
    border-bottom-width: 0.1em;
    padding-bottom: 1em;
    margin-bottom: 1em;
}

.DumEC2ipjgCw3fTHi8UQiQ\=\= {
    line-height: 1em;
    padding: 0 1em 0 1em;
}

hr.UpTsJS8Z9me2Hiihukmv6g\=\= {
    border-top: 0.5em double #808080;
    width: 16em;
}

._72j2XdGqre5FtGJrsrH3ew\=\= {
    cursor: pointer;
    margin: auto;
}

@media screen and (max-device-width: 840px) {
    .Em-oYqy8e57Is80h6h0BEQ\=\= {
        width: 100% !important;
        margin-left: 0.5em !important;
    }

    .AZfZtNENBg1QIlvL4oOZ8Q\=\= {
        font-size: larger;
    }

    ._3-DfwT2a2wGMoPFIMFH1EA\=\= {
        font-size: larger;
        margin-top: 0;
    }

    .AGh9DYCoMmSJ\+wugtUxsxg\=\= {
        font-size: larger;
    }

    .ZyUCTMn4\+OZ5tIe8izB8WQ\=\= {
        font-size: larger;
    }

    section > div > input {
        font-size: larger;
    }

    section > h2 {
        line-height: unset !important;
    }

    .VpKDOkxdY6Wa2SztgcyN0Q\=\=, .pUYu4g7VmFXomz\+XJefDZg\=\= {
        float: right;
        width: 1em;
        cursor: pointer;
        padding-left: 0 !important;
    }

    .VpKDOkxdY6Wa2SztgcyN0Q\=\= {
        font-size: larger;
    }

    a._72j2XdGqre5FtGJrsrH3ew\=\= > i {
        font-size: xx-large !important;
    }
}

.MQ2TaQByQwINJqsqghUnVg\=\= {
    border: #FFFFFF;
    margin: 0.2em;
    padding: 0.2em;
    border-radius: 0.5em;
    background-color: #D4DCE7;
    width: 10em;
}
.ztJO1v2bzqwk91wbpNeB3g\=\= {
	padding: 10px 0px;
	border-top: 1px solid gray;
}

.FOk2jp3YhPOGSvvJvWW8bg\=\= {
	margin-left: 1em;
	margin-top: 0.6em;
	margin-bottom: -0.5em;
	width: 90%;
}

._4D0BGc776C\+B0Yj2nQpLuQ\=\= {
	width: 1em;
}

._7F8dFQgoHTAQwA4R3TU\+IA\=\= {
	margin-left: 1.1em;
}
.eNesIp3\+h1SaFo5EyCnzCA\=\= {
    background: lightblue;
    display: table;
    width:100%;
}
        
input[type=button] {    
    vertical-align: middle;
    display: block;
    width: 50%;
    margin: 0 auto;
}
        
._37hJCReCaXGXWPwHYFcYNg\=\= {
    background: darkorange;
    display: table-cell;
    vertical-align: middle;
}

@media screen and (max-device-width: 840px) {
    ._3Q5cj458AggrVNVWDHt\+-g\=\= {
        margin-top: 1em !important;
        margin-left: 50% !important;
        --mdc-theme-secondary: #ff4081;
        --mdc-theme-secondary-light: #ff87b0;
        --mdc-theme-secondary-dark: #f80054;
    }
}

._3Q5cj458AggrVNVWDHt\+-g\=\= {
    margin: 1em 1em 1em 13em;
    --mdc-theme-secondary: #ff4081;
    --mdc-theme-secondary-light: #ff87b0;
    --mdc-theme-secondary-dark: #f80054;
}

._1wilw2\+btAfmfEZz3GmauQ\=\= {
  display: inline-block;
}

@media screen and (max-device-width: 840px) {
    .eJqKNQbnpKV5oOCZF8hhgg\=\= {
        width: 100vw !important;
        margin: auto !important;
        height: auto !important;
        overflow-y: scroll !important;
        float: none !important;
    }
    .jepFbtj80nrUUMU6CInHxg\=\= {
        width: 60em !important;
        margin: 1em !important;
    }
}

.K7\+GCnM6QBUrNeYGxnYblA\=\= {
    padding: 2em;
    text-align: center;
}

.QHAhYm6Knw48Fiwyp8f6mQ\=\= {
    height: calc(100vh - 4em);
    width: calc(100vw - 25em);
}

.v8Ot1Y73vqTFBTik30kCTg\=\= {
    margin: 4em 0em 0em 0em;
    float: right;
}

.eJqKNQbnpKV5oOCZF8hhgg\=\= {
    width: 25em;
    margin: 0em 0.1em 3em 0.1em;
    float: left;
    height: calc(100vh - 30em);
    overflow-y: auto;
}

.QlUG0MbuyBtPX8CznTgvAw\=\= {
    display: flex;
}

.jxS\+EimFcszPrYrQJcjA3A\=\= {
    position: fixed;
    width: 100%;
    margin-bottom: 5em;
    z-index: 100;
}

.Cxx905Cwv7c7Hxo5Z3Br2Q\=\= {
    max-height: 30em;
    width: 22.2em;
    margin: 1em 0.5em 0.5em 0.2em;
    padding: 1em 1em;
    text-align: left;
    display: inline-block;
    overflow-y: auto;
    overflow-x: auto;
}

.EhcuGK-S5tNDMgNqJ0L3vA\=\= {
    float: left;
}

.k\+vGmCgPUWHdnZz8qiJqig\=\= {
    padding-top: 1em;
    padding-left: 1em;
    float: left;
    width: 15em;
}

._5ETGpLs9Z-t82n\+R-iGE0A\=\= {
    overflow-y: auto;
    height: 9em;
}

.M6asmkqtjP1HKzFYBByxgQ\=\= {
    margin-left: auto;
    text-align: center;
    padding: 0.5em 0.5em 0.5em 5em;
    position: fixed;
    bottom:0;
    z-index: 100;
    background-color: #ffffff;
}

.eWzWEol5Pmv4\+TyrDLYKSQ\=\= {
    margin-top: 1em;
    margin-left: 1em;
    float: left;
    width: 45%;
}

.zDvFgGnktnqQP\+tWrWOldw\=\= {
    float: left;
    margin-top: 1em;
    margin-left: 1em;
    text-align: left;
}

.zaDQavODrmmlnWKNcliyRw\=\= {
    margin: 1em 0.1em 0.5em 1.1em;
    width: 22em;
}

.ju5YrVMM3miOXKCLopu27Q\=\= {
    margin-top: 2em;
}

._8TRmAueXrm3\+3ZXC5twzFw\=\= {
    width: 9em;
}

.jepFbtj80nrUUMU6CInHxg\=\= {
    max-height: 16em;
    overflow-y: auto !important;
    overflow-x: auto !important;
    width: 24em;
    margin: 1em 0em 0.5em 1em;
    padding: 0.2em 0.2em 0.2em 0em !important;
    top: 7.3em;
    z-index: 100;
    background-color: #ffffff;
    display: inline-block !important;
}

.qsU1rgI4DnlHE0cRFnG7gA\=\= {
    padding: 0.5em;
}
@media screen and (max-device-width: 840px) {
    .pPZPVaKxJOvirHvMUDTVlQ\=\= {
        width: 100vw !important;
        margin: auto !important;
        height: auto !important;
        overflow-y: scroll !important;
        float: none !important;
    }

    .aJnYKSTFDXxTzcKgeiJsTg\=\=,
    .sgwAxSeuhr17RjCbYeyszQ\=\=,
    .LU9ty-9YlYUP5jrZNgp9Tw\=\= {
        width: 100% !important;
        margin-left: 0.5em !important;
    }
}

.pPZPVaKxJOvirHvMUDTVlQ\=\= {
    width: 24em;
    float: left;
    height: calc(100vh - 10em);
    overflow-y: auto;
}

.cV3s-71Bom51eUKhLP7HDw\=\= {
    max-height: 30em;
    width: 22.2em;
    margin: 1em 0.5em 0.5em 0.2em;
    padding: 1em 1em;
    text-align: left;
    display: inline-block;
    overflow-y: auto;
    overflow-x: auto;
}

.UKPLnhNL5dsXPgIkSjR8jQ\=\= {
    width: 20em;
    padding: 1em;
    display: inline-block;
    margin-left: 2em;
    margin-bottom: 2em;
}

.xqQVZBmoFpQjZoLIYGtagA\=\= {
    margin-top: 2em;
    max-width: calc(100%) !important;
}

.xOvHrjHjaKPpguBmGGN0GA\=\= {
    width: 25em;
    margin-top: 2.5em !important;
    height: calc(100vh - 12em);
    overflow-y: auto;
}

._30AkCHaxWcq6y0\+dR1qKWA\=\= {
    margin-top: 4em;
    margin-left: 2em;
}

.s6Xa9wegSmIfkO9yt0mW-g\=\= {
    padding: 0.2em 0.2em 0.2em 1em !important;
    width: 100%;
    position: absolute;
    background-color: #ffffff;
    z-index: 1;
}

.rfqa0nLUawlUJGHgfTqwfA\=\= {
    padding-left: 2em;
    list-style: none;
}

._6JYkb4k8ZRLaFR5ZHSSxng\=\= {
    width: 8em;
    margin-bottom: -1em;
}

.df8-8dgscepyDRNAogSV4g\=\= {
    float: left;
    margin-top: 1em;
    margin-left: 1em;
    text-align: left;
}

.z2mzB7S2AExilNlrkMGBcQ\=\= {
    margin-top: 1em;
    margin-left: 1em;
    float: left;
    width: 45%;
}

.aJnYKSTFDXxTzcKgeiJsTg\=\= {
    width: 21em;
    margin: 0em 0.1em 1em 0.1em;
}

.sgwAxSeuhr17RjCbYeyszQ\=\= {
    width: 21em;
    margin: 0em 0.1em 1em 0.1em;
}

.LU9ty-9YlYUP5jrZNgp9Tw\=\= {
    margin: 0em 0.1em 1em 0.1em;
    width: 21em;
}

.iNB4WLVT46yQ0sxwhnCvOA\=\= {
    background-color: #3f51b5;
    padding: 0.5em;
    border-radius: 5em;
    font-weight: bold;
    color: white;
}

.hS3u8HkbOgBhkQAEiwYRZA\=\= {
    text-transform: capitalize;
}

.GqDw7m981zEv1mBGap7hlg\=\= {
    position: absolute;
    top: 9em;
    right: 0.8em;
}

.MmC4EJuLsxbXGPSBMm2UoQ\=\= {
    float: right;
    cursor: pointer;
}

.wFGrV-R3Oh4ss4BABjrMDA\=\= {
    color: red;
    cursor: pointer;
}
